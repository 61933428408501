.App {
  position: relative;
  width: 1600px;
  height: 1024px;
  background: #313131;
}
.Cont1 {
  position: absolute;
  width: 903px;
  height: 320px;
  left: 15px;
  top: 15px;

  border: 1px solid #484848;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
}
.Cont11{
  position: absolute;
width: 410px;
height: 319px;
left: 491px;
top: 0px;

border: 1px solid #484848;
box-sizing: border-box;
border-radius: 0px 10px 10px 0px;
}
.Txt11{
  position: absolute;
  width: 363px;
  height: 33px;
  left: 20px;
  top: 71px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;

  color: #efefef;

}
.Txt112{
  position: absolute;
width: 88px;
height: 33px;
left: 20px;
top: 105px;

font-family: Open Sans;
font-style: normal;
font-weight: 100;
font-size: 18px;
line-height: 33px;
color: #ffffff93;
}
.Cont111{
  position: absolute;
  width: 37px;
  height: 46px;
  left: 373px;
  top: -2px;
  
  border: 1px solid #484848;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius:  0px 10px 0px 10px;
}
.Txt121{
  position: absolute;
width: 400px;
height: 33px;
left: 16px;
top: 41px;

font-family: Open Sans;
font-style: normal;
font-weight: 300;
font-size: 22px;
line-height: 33px;



color: #6c6c6c;
}
.Txt122{
  position: absolute;
width: 111px;
height: 21px;
left: 16px;
top: 91px;

font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;


color: #ffffff93;
}
.Line1{
  position: absolute;
width: 50px;
height: 0px;
left: 168.99px;
top: 111px;

border: 1px solid;
color:#e6cc09;
}
.Line2{
  position: absolute;
  width: 50px;
  height: 0px;
  left: 168.99px;
  top: 170px;

  border: 1px solid #5e682e;
}
.Line3{
  position: absolute;
  width: 50px;
  height: 0px;
  left: 168.99px;
top: 210px;

border: 1px solid #08eba3;
}
.Txt123{
  position: absolute;
width: 21px;
height: 21px;
left: 250px;
top: 101px;

font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;



color: #efefef;
}
.Cont2 {
  position: absolute;
  width: 475px;
  height: 320px;
  left: 951px;
  top: 15px;
  
  border: 1px solid #484848;
  box-sizing: border-box;
  border-radius: 10px;
}
.Txt21{
  position: absolute;
width: 220px;
height: 33px;
left: 25px;
top: 41px;

font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 33px;
/* identical to box height */


color: #efefef;

}
.Txt22{
  position: absolute;
width: 54px;
height: 25px;
left: 25px;
top: 80px;

font-family: Open Sans;
font-style: normal;
font-weight: 300;
font-size: 18px;
line-height: 25px;



color: #ffffff93;

}
.ContMid2{
  position: absolute;
width: 475px;
height: 48px;
left: 5px;
top: 11px;

border: 1px solid #484848;
box-sizing: border-box;
}
.Cont22{
  position: absolute;
width: 475px;
height: 110px;
left: 0px;
top: 210px;

border: 1px solid #484848;
box-sizing: border-box;
border-radius: 0px 0px 10px 10px;
}
.Cont221{
  position: absolute;
width: 154px;
height: 108px;
left: 0px;
top: 0px;

border: 1px solid #484848;
box-sizing: border-box;
border-radius: 0px 0px 0px 10px;
}
.Cont222{
  position: absolute;
width: 154px;
height: 108px;
left: 319px;
top: 0px;

border: 1px solid #484848;
box-sizing: border-box;
border-radius: 0px 0px 10px 0px;
}
.Line4{
  position: absolute;
  width: 50px;
  height: 0px;
  left: 20px;
  top: 60px;

  border: 1px solid #e09710;
}
.Line5{
  position: absolute;
  width: 50px;
  height: 0px;
  left: 20px;
  top: 60px;

  border: 1px solid #a7bd78
  

  ;
}
.Cont3{
  position: absolute;
  width: 440px;
  height: 320px;
  left: 15px;
  top: 352px;

  border: 1px solid #484848;
  box-sizing: border-box;
  border-radius: 10px;
}
.Cont4{
  position: absolute;
width: 440px;
height: 320px;
left: 471px;
top: calc(50% - 320px/2);

border: 1px solid #484848;
box-sizing: border-box;
border-radius: 10px;
}
.Cont5{
  position: absolute;
width: 472px;
height: 320px;
left: 951px;
top: 352px;

border: 1px solid #484848;
box-sizing: border-box;
border-radius: 10px;
}
.Cont51{
  position: absolute;
width: 110px;
height: 20px;
left: 25px;
top: 20px;

border: 1px solid #666666;
box-sizing: border-box;
border-radius: 2px;
background-color: #666666;
}
.Cont6{
  position: absolute;
width: 440px;
height: 320px;
left: 15px;
top: 692px;

border: 1px solid #484848;
box-sizing: border-box;
border-radius: 10px;
}

.Cont7{
  position: absolute;
width: 440px;
height: 320px;
left: 471px;
top: 692px;

border: 1px solid #484848;
box-sizing: border-box;
border-radius: 10px;
}
.Cont8{
  position: absolute;
width: 472px;
height: 320px;
left: 951px;
top: 692px;

border: 1px solid #484848;
box-sizing: border-box;
border-radius: 10px;
}